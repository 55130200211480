import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.logo}>
        <p className={styles.logotipo}>REM | Bodas</p>
        <p>Copyright: Rem Bodas 2022</p>
      </div>

      <div className={styles.redes}>
        <p>Visitanos en nuestras redes sociales</p>
      </div>

      <div className={styles.contacto}>
        <p>
          <strong>Contactanos</strong>
        </p>
        <p>Email: <a href="mailto:remstudiop@gmail.com">remstudiop@gmail.com</a> </p>
        <div className={styles.whatsappFooter}>
          <p>Whatsapp: 55 8057 6792</p>
          <a href="https://api.whatsapp.com/send?phone=5215580576792&text=Hola%2C%20estoy%20interesado%20en%20sus%20servicios."><img src="../images/Logo-WhatsApp.png" width={50} alt="Whatsapp" /></a>
        </div>
      </div>
    </div>
  );
}
