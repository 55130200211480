import styles from "./Proximamente.module.css"

export default function Proximamente(){
    return (
        <div className={styles.proximamente}>
        <h1 className={styles.logoPrincipal}>REM | Bodas</h1>
        <p>Estamos remodelando el sitio para entregarte la nueva plataforma para tus eventos.</p>
        <h2>Regresamos 2023</h2>
        </div>
    )
}