import './App.css';
import {Navbar, Nav, Container, Carousel, Button, Accordion} from "react-bootstrap";
import Proximamente from './components/Proximamente';
import Footer from './components/Footer';

function App() {
    /* const [index, setIndex] = useState(0);
  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    }; */
  
  return (
    <>
      {/* <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href='#home'>R|B</Navbar.Brand>
        <Nav>
          <Nav.Link href='#paquetes'>Paquetes</Nav.Link>
          <Nav.Link href='#portafolio'>Portafolio</Nav.Link>
          <Nav.Link href='#contacto'>Contacto</Nav.Link>
        </Nav>
      </Container>
    </Navbar>
    <Container>
    <div className='jumbotron'>
      <h1>REM | BODAS</h1>
    </div>
    </Container>
    <Container>
    <section>
      <div>
        <h2>Tu boda como una película romántica.</h2>
      </div>
    </section>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img
          className="d-block w-50"
          src="./images/REMWebImages-19.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-50"
          src="./images/REMWebImages-21.jpg"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-50"
          src="./images/REMWebImages-25-2.jpg"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </Container>
    <Container>
      <h3>Dí acepto a una boda de película</h3>
      <Button variant="secondary">
        Ir a paquetes
      </Button>
    </Container>

    <Container>
      <h2>Agenda una cita o videollamada</h2>
      <Button>Messenger</Button>
      <Button>Whatsapp</Button>
      <p>Aparta tu fecha antes de que la ocupen</p>
    </Container>
    <Container>
      <h2>Da click en los paquetes para ver lo que incluyen</h2>
      <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Accordion Item #1</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Accordion Item #2</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Accordion Item #3</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Accordion Item #4</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </Container>
    <Container>
      <h2>Contacto</h2>
      <Button>Messenger</Button>
      <Button>Whatsapp</Button>
    </Container>
    <Container>
      <p>Nos ubicamos en <br />
      Lomas de Atizapan, Atizapan de Zaragoza, Estado de México</p>
      <p><strong>Tenemos servicio en toda la República Méxicana</strong></p>
      <p>Siguenos en Facebook e Instagram:</p>
      <p>Copyright 2022: Rem Bodas</p>
    </Container>*/}
    <Proximamente />
    <Footer />
    </> 
  );
}

export default App;
